import revive_payload_client_Pa7rsAWYTI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_awyJUwZeFN from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_v1OCCONVyI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_fHOqkjSFRC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_f3pKXrR1o6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vNDvlW6Knd from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qQ4TgAIn2h from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Uh1ooq9oBJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@2.79.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_BgDihNaN7u from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_QIBK0pZo7N from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_next-auth@4.21.1_rollup@2.79.2_vue@3.5.12/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_DIxJ7xZIPW from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@2.79.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import vuetify_icons_bDb11WqzvB from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_rollup@2.79.2_typescript@5.6.3_vite@5.4.10_vue@3.5.12/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_LdOtnK98rd from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_rollup@2.79.2_typescript@5.6.3_vite@5.4.10_vue@3.5.12/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import templates_pwa_client_0b4b5e5a_q0o0w6bs0d from "/vercel/path0/.nuxt/templates.pwa.client.0b4b5e5a.ts";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import vue_datepicker_dyozMmv23j from "/vercel/path0/plugins/vue-datepicker.ts";
import vue_draggable_OKRqLUiGtd from "/vercel/path0/plugins/vue-draggable.ts";
import vuetify_bA854Niu1N from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_rollup@2.79.2_typescript@5.6.3_vite@5.4.10_vue@3.5.12/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_Pa7rsAWYTI,
  unhead_awyJUwZeFN,
  router_v1OCCONVyI,
  navigation_repaint_client_fHOqkjSFRC,
  check_outdated_build_client_f3pKXrR1o6,
  chunk_reload_client_vNDvlW6Knd,
  plugin_vue3_qQ4TgAIn2h,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Uh1ooq9oBJ,
  plugin_client_BgDihNaN7u,
  plugin_QIBK0pZo7N,
  plugin_DIxJ7xZIPW,
  vuetify_icons_bDb11WqzvB,
  vuetify_no_client_hints_LdOtnK98rd,
  templates_pwa_client_0b4b5e5a_q0o0w6bs0d,
  hotjar_pBWYs5JQbk,
  vue_datepicker_dyozMmv23j,
  vue_draggable_OKRqLUiGtd,
  vuetify_bA854Niu1N
]