import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_939rArDAEosqMeta } from "/vercel/path0/pages/list/[id].vue?macro=true";
import { default as listsIm5cAseXMXMeta } from "/vercel/path0/pages/lists.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as _91id_93YjTnfCyYZ0Meta } from "/vercel/path0/pages/todo/[id].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "list-id",
    path: "/list/:id()",
    component: () => import("/vercel/path0/pages/list/[id].vue")
  },
  {
    name: "lists",
    path: "/lists",
    meta: listsIm5cAseXMXMeta || {},
    component: () => import("/vercel/path0/pages/lists.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue")
  },
  {
    name: "todo-id",
    path: "/todo/:id()",
    meta: _91id_93YjTnfCyYZ0Meta || {},
    component: () => import("/vercel/path0/pages/todo/[id].vue")
  }
]